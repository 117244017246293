<template>
  <transition name="fade">
    <div id="pagetop" class="bt--top right-0 bottom-0" v-show="scY > 300" @click="toTop">
      <i class="fas fa-arrow-circle-up"></i>
    </div>
  </transition>
</template>

<script>
  export default {
    name: "BtnTop",
    data() {
      return {
          scTimer: 0,
          scY: 0,
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
      handleScroll() {
          if (this.scTimer) return;
          this.scTimer = setTimeout(() => {
              this.scY = window.scrollY;
              clearTimeout(this.scTimer);
              this.scTimer = 0;
          }, 100);
      },
      toTop() {
          window.scrollTo({
              top: 0,
              behavior: "smooth"
          });
      }
    }
  }
</script>
