import { data, API } from '@/shared';

export default {
  namespaced: true,
  state: {
    flags: null,
    sites: null,
    sitesData: null,
    site: null,
    plans: null,
    clicks: null
  },
  mutations: {
    updateSites(state, sites) {
      state.sites = sites;
    },
    updateSite(state, site) {
      state.site = site;
    },
    updateFlags(state, flags) {
      state.flags = flags;
    },
    updatePlans(state, plans) {
      state.plans = plans;
    },
    updateClickIdList(state, clicks) {
      state.clicks = clicks;
    },
    setSortedSites(state, sortedSites) {
      state.sitesData = sortedSites;
    }
  },

  actions: { ///sites?lang=&category=&genre=&sortby=&offset=&limit=
    async getFlagsAction({ commit }) {
      const flags = await data.getData("/data/langs.json");
      commit("updateFlags", flags);
    },
    async getSitesAction({ commit }, params) {
      const sites = await data.getSites(`${API}/sites`, params);
      commit("updateSites", sites);
    },
    async getSiteAction({ commit }, params) {
      const site = await data.getSite(`${API}/site/${params.id}`, {lang:params.lang});
      commit("updateSite", site);
    },
    async getPlansAction({ commit }) {
      const plans = await data.getData("/data/commission_plans.json");
      commit("updatePlans", plans);
    },
    async getClickIdListAction({ commit }, params) {
      const clicks = await data.getData(`${API}/site/clickid_list/${params.id}`, {lang: params.lang});
      commit("updateClickIdList", clicks);
    },
    sortBy({ commit, state },sort_key) {
      console.log(sort_key)
      let sortedData = [...state.sites];
      sortedData.sort((a, b) => {
        let order = 1;
        if (sort_key === 'site_name_asc') {
          const nameA = a.site_name.toUpperCase();
          const nameB = b.site_name.toUpperCase();
          if (nameA < nameB) return -order;
          if (nameA > nameB) return order;
          return 0;
        } else if(sort_key === 'site_id')  {
          if (a[sort_key] === b[sort_key]) return 0;
          return a[sort_key] < b[sort_key] ? -order : order;
        }else {
          order = -1
          if (a[sort_key] === b[sort_key]) return 0;
          return a[sort_key] < b[sort_key] ? -order : order;
        }
      });
      commit('setSortedSites', sortedData)
    }
  }
}
