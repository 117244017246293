import i18n, { selectedLocale } from '../../i18n'
export default {
  namespaced: true,
  state: {
    locale: selectedLocale,
  },
  mutations: {
    updateLocale(state, newLocale) {
      state.locale = newLocale
      document.body.className = localStorage.selectLang = newLocale
    }
  },
  actions: {
    getLocaleActions({ commit }, newLocale) {
      console.log(newLocale)
      i18n.locale = newLocale || selectedLocale// important!
      commit("updateLocale", i18n.locale)
    }
  }
}
