export const getValues = {
  inheritAttrs: false,
  data() {
    return {
      values: []
    }
  },
  watch: {
    "values": {
      handler(e) {
        this.$_getValuesSelected(e);
      }
    }
  },
  computed: {
    lang() {
      return this.$store.state.locales.locale;
    }
  },
  methods: {
    $_getValuesName(array) {
      if (this.$attrs.datatype == "site_id") {
        return array.name;
      } else if (this.$attrs.datatype == "sales") {
        return this.$i18n.t("sales." + array)
      } else {
        let array_key = 'name_' + this.lang;
        if (array[array_key] == undefined) {
          return array.name;
        } else {
          return array[array_key];
        }
      }
    },
    $_getValuesSelected(e) {
      let arr = Array.isArray(e) ? e : [e]
      this.$emit('input', arr)
    },
    $_getValuesResetAll() {
      this.values = []
    }
  }
}

export const setMetaTitle = {
  mounted() {
    this.$_setMetaTitleSpageTitle();
  },
  watch: {
    'lang': {
      handler() {
        this.$_setMetaTitleSpageTitle();
      }
    }
  },
  methods: {
    $_setMetaTitleSpageTitle() {
      let { title } = this.$options;
      if (title) {
        title = typeof title === 'function' ? title.call(this) : title;
        console.log(title)
        let txt = `${this.$i18n.t(title + '.title')}`
        document.title = txt;
      }
    }
  }
}

export const imageSet = {
  methods: {
    $_imageSetImage(img_url, siteid, sitename) {
      let img_class = "site" + siteid,
        error_img = `${this.$imgUrl}/sites/no-img.png`,
        img2x = "",
        srcset = "",
        target = "/" + this.lang + "/";

      if (img_url !== undefined) {
        img2x = img_url.replace(target, target + "2x/");
        srcset = `srcset="${img_url} 1x, ${img2x} 2x"`;
        return `<img onerror="this.onerror=null;this.src='${error_img}';" class="${img_class}" ${srcset} src="${img_url}" alt="${sitename}">`;
      } else {
        return `<img src="${error_img}" class="${img_class}" alt="${sitename}">`;
      }
    }
  }
}

export const setFlags = {
  async created() {
    await this.$store.dispatch('sites/getFlagsAction');
  },
  computed: {
    lang_flags() {
      return this.$store.state.sites.flags;
    }
  },
  methods: {
    $_setFlagsLangs(siteid, flags_array) {
      let html = ''
      for (var i in flags_array) {
        let target = flags_array[i];
        if (siteid == target.site_id) {
          for (var lang in target.langs) {
            let flag = target.langs[lang]
            if (flag != "null") {
              html += `<img src="${this.$imgUrl}/lang/${flag}.svg" alt="${flag} flag">`;
            }
          }
        }
      }
      return html;
    }
  }
}

export const setMobile = {
  data() {
    return {
      sp: true
    }
  },
  created() {
    window.addEventListener('resize', () => {
      console.log('Window resized');
      this.mobileSet
    });
    this.mobileSet();
  },
  destroyed() {
    window.removeEventListener('resize', this.mobileSet);
  },
  methods: {
    mobileSet: function () {
      if (window.innerWidth <= 1128) {
        this.sp = true
      } else if (window.innerWidth > 1127) {
        this.sp = false

      }
    },
    spSection() {
      this.sp = !this.sp
    }
  }
}

export const setGenre = {
  methods: {
    $_setGenreType(id, lang, type_array) {
      let array = "";
      if (id instanceof Array) {
        for (var array_item in id) {
          for (var i in type_array) {
            let item = type_array[i]
            if (id[array_item] == item.id) {
              array += "<span class='genre'>";
              array += lang == "en" ? item.name_en : item.name_ja;
              array += "</span>";
            }
          }
        }
      } else {
        for (var ii in type_array) {
          let item = type_array[ii]
          if (id == item.id) {
            array += lang == "en" ? item.name_en : item.name_ja;
          }
        }
      }
      return array;
    },
    $_setGenreSetNames(hash) {
      let str = '';
      for (var gname in hash) {
        str += '<span class="g_name">' + hash[gname].name + '</span>';
      }
      let genrenames = str.slice(0, -2);
      return genrenames;
    }
  }
}

export const setPageTitle = {
  created() {
    this.$_setPageTitlePageTitle();
  },
  methods: {
    $_setPageTitlePageTitle() {
      let str = this.$route.name,
        route_name = str.toLowerCase(),
        p_title = "";
      switch (route_name) {
        case "site":
          p_title = this.$route.params.siteid;
          break;
        case "ad":
          p_title = this.$route.params.siteid + ': ' + this.$route.params.bannerid;
          break;
        case "pinktok":
          p_title = 'PinkTok';
          break;
        case "reports":
          break;
        default:
          p_title = this.$i18n.t(route_name + ".title");
          break;
      }
      document.title = p_title + ' - ' + this.$i18n.t('sitetitle');
      return p_title;
    }
  }
}

export const setSideMenu = {
  data() {
    return {
      isSideRight: false,
      isSideLeft: true,
    }
  },
  methods: {
    $_closeSidebar() {
      this.isSideLeft = !this.isSideLeft
    }
  }
}

export const createRowIndex = {
  data() {
    return {
      report_types: {
        new_signup_count: "new_signup",
        recurring_count: "recurring",
        addition_count: "additional",
        other_count: "others",
        deduction_count: "deduction",
        total_unique_hits: "clicks",
        tier_count: "tier",
        ppc_uniques: "ppc",
        performer_id: "pf",
        user_id: "recurring_user",
        unsubscribe_count: "unsubscribe",
        username: "username"
      }
    }
  },
  computed: {
    item_labels() {
      return this.column ? this.$_setLavels(this.column) : false
    }
  },
  methods: {
    $_renderRowIndex({ rowIndex }) {
      return <span> {rowIndex + this.startRowIndex + 1} </span>;
    },
    $_setLavels(object) {
      let ary = object.map((item) => {
        if (item.key == undefined) {
          return item.children.map(child => child.key);
        } else {
          return item.key;
        }
      }),
        str = ary.join(','),
        lavels = str.split(',')
      return lavels
    }
  }
}

export const displayGuide = {
  data() {
    return {
      isShowGuide: {
        show: false,
        type: ""
      }
    }
  },
  methods: {
    $_closeGuide() {
      this.$emit("closeGuide");
    },
    $_displayGuide(name) {
      this.$emit("displayGuide", String(name));
    },
    $_isGuide(e, name) {
      this.isShowGuide.show = !this.isShowGuide.show
      this.isShowGuide.type = String(e)
      this.isShowGuide.name = String(name)
    },
    $_isGuideChild(e) {
      this.$emit("displayGuide", e);
    },
    $_isCloseGuide() {
      this.isShowGuide.show = false
      this.isShowGuide.type = ""
    }

  }
}