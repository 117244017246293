import enBase from './en/base'
import enAccounts from './en/accounts'
import enTools from './en/tools'
import enSales from './en/sales'
import enAds from './en/ads'
import enSites from './en/sites'
import enSidebar from './en/sidebar'
import enTop from './en/top'
import enErrors from './en/error_notice'
import enMail from './en/mail'
import enBprograms from './en/b_programs'
import enFront from './en/front_page'

import jaBase from './ja/base'
import jaAccounts from './ja/accounts'
import jaTools from './ja/tools'
import jaSales from './ja/sales'
import jaAds from './ja/ads'
import jaSites from './ja/sites'
import jaSidebar from './ja/sidebar'
import jaTop from './ja/top'
import jaErrors from './ja/error_notice'
import jaMail from './ja/mail'
import jaBprograms from './ja/b_programs'
import jaBonusprograms from './ja/bonusprograms'
import jaFront from './ja/front_page'

// jsonを結合する
var dataEN = Object.assign(enBase, enAccounts, enSidebar, enTools, enSales, enAds, enSites, enTop, enErrors, enMail, enBprograms, enFront),
    dataJA = Object.assign(jaBase, jaAccounts, jaSidebar, jaTools, jaSales, jaAds, jaSites, jaTop, jaErrors, jaMail, jaBprograms,jaBonusprograms, jaFront),
    data = { "en": dataEN, "ja": dataJA }


export { data }
