import { data, API } from '@/shared';

export default {
  namespaced: true,
  state: {
    bgenre: null,
    sgenre: null,
    category: null,
    type: null
  },
  mutations: {
    updateBannerGenre(state, bgenre) {
      state.bgenre = bgenre;
    },
    updateSiteGenre(state, sgenre) {
      state.sgenre = sgenre;
    },
    updateCategory(state, category) {
      state.category = category;
    },
    updateType(state, type) {
      state.type = type;
    }
  },
  actions: { ///ads?lang=&category=&genre=&sortby=&offset=&limit=
    async getBannerGenreAction({ commit }) {
      const bgenre = await data.getData(`${API}/banners/genre`);
      const sortedData = bgenre.sort((a, b) => {
          if ((a.id === 9 || a.id === 10) && !(b.id === 9 || b.id === 10)) return -1;
          if ((b.id === 9 || b.id === 10) && !(a.id === 9 || a.id === 10)) return 1;
        })
      commit("updateBannerGenre", sortedData);
    },
    async getSiteGenreAction({ commit }) {
      const sgenre = await data.getData(`${API}/sites/genre`);
      commit("updateSiteGenre", sgenre);
    },
    async getCategoryAction({ commit }) {
      const category = await data.getData(`${API}/sites/category`);
      commit("updateCategory", category);
    },
    async getTypeAction({ commit }) {
      const type = await data.getData(`${API}/banners/type`);
      commit("updateType", type);
    }
  }
}
