import { data, API } from '@/shared';

export default {
  namespaced: true,
  state: {
    banks: null,
    cancel: null,
    countries: null,
    currencies: null,
    // sendemail: null,
    invoices: null,
    invoice: null,
    methods: null,
    payment: null,
    payment_type: null,
    profile: null,
    splan: null,
    bonusAmount: null,
    ach_allow: ['273', '15677', '66533', '85307', '91815', '92504', '111305', '112919', '154101', '173772']
  
  },
  mutations: {
    updateBanks(state, banks) {
      state.banks = banks;
    },
    updateCancel(state, cancel) {
      state.cancel = cancel;
    },
    updateCountries(state, countries) {
      state.countries = countries;
    },
    updateCurrencies(state, currencies) {
      state.currencies = currencies;
    },
    // updateSendsendemail(state, sendemail) {
    //   state.sendemail = sendemail;
    // },
    updateInvoice(state, invoice) {
      state.invoice = invoice;
    },
    updateInvoices(state, invoices) {
      state.invoices = invoices;
    },
    updatePaymentMethods(state, methods) {
      state.methods = methods;
    },
    updatePayment(state, payment) {
      state.payment = payment;
    },
    updatePaymentType(state, payment_typ) {
      state.payment_type = payment_typ;
      console.log(state.payment_type)
    },
    updateProfile(state, profile) {
      state.profile = profile;
    },
    updateSplan(state, splan) {
      state.splan = splan;
    },
    updateBonusAmount(state, bonusAmount) {
      state.bonusAmount = bonusAmount;
    },
    resetAccounts(state) {
      state.profile = null
    }
  },
  actions: {
    async getBanksAction({ commit }) {
      const banks = await data.getData(`${API}/banks`);
      commit("updateBanks", banks);
    },
    async getCancelAction({ commit }) {
      const cancel = await data.getData(`${API}/account/profile/cancel_view`);
      commit("updateCancel", cancel[0]);
      return cancel[0]
    },
    async updateCancelAction({ commit }) {
      const cancel = await data.updateCancel();
      commit("updateCancel", cancel);
      return cancel
    },
    async getCurrenciesAction({ commit }) {
      const currencies = await data.getData("/data/currency.json");
      commit("updateCurrencies", currencies);
    },
    async getCountriesAction({ commit }) {
      const countries = await data.getData("/data/country.json");
      commit("updateCountries", countries);
    },
    async getInvoiceAction({ commit }, params) {
      console.log('call getInvoiceAction')
      const invoice = await data.getData(`${API}/invoice`, params);
      commit("updateInvoice", invoice[0]);
      return invoice[0]
    },
    async sendInvoiceAction({ commit }) {
      // const invoice = await data.putData(`${API}/invoice/request`)
      const invoice = await data.createInvoice();
      commit('updateInvoice', invoice);
      return invoice
    },
    async getInvoicesAction({ commit }) {
      const invoices = await data.getData(`${API}/invoices/history`);
      commit("updateInvoices", invoices);
      return invoices
    },
    async getPaymentAction({ commit }, param) {
      const payment = await data.getData(`${API}/account/payment`, param);
      commit("updatePayment", payment);
    },
    async addPaymentAction({ commit }, param) {
      const payment = await data.postData(`${API}/account/payment/add`, param);
      commit("updatePayment", payment);
    },
    async getPaymentTypeAction({ commit }) {
      const methods = await data.getData(`${API}/account/payment_requirements`);
      commit("updatePaymentMethods", methods);
    },
    async updatePaymentTypeAction({ commit }, param) {
      const payment_typ = await data.putData(`${API}/account/payment_type/update`, param);
      console.log('payment_type')
      commit("updatePaymentType", payment_typ[0].payment_type);
    },
    async updatePaymentPrimaryAction({ commit }, param) {
      const payment = await data.putData(`${API}/account/payment_primary`, param);
      console.log('updatepayment',payment)
      // commit("updatePayment", payment);
    },
    async getProfileAction({ commit }) {
      const profile = await data.getData(`${API}/account/profile`);
      commit("updateProfile", profile[0]);
      return profile[0]
    },
    async updateProfileAction({ commit }, param) {
      const profile = await data.putProfile(`${API}/account/profile/update`, param);
      commit("updateProfile", profile);
      return profile
    },
    // async sendsendemailAction({ commit }, param) {
    //   const sendemail = await data.sendsendemail(param);
    //   commit("updateSendsendemail", sendemail);
    // },    
    async getStudyPlanAction({ commit }) {
      const splan = await data.getData(`${API}/account/study_plan`);
      commit("updateSplan", splan);
      return splan
    },  
    async getBonusAmountAction({ commit }) {
      const arr = await data.getData(`${API}/account/study_plan`);
      let amount = 0;
      for (let i = 0; i < arr.length; i++){
          amount = amount + Number(arr[i].amount);
      };
      commit("updateBonusAmount", amount);
    },
    resetAccountsState({ commit }) {
      commit("resetAccounts");
    }
  }
}
