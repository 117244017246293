export const textValidation = {
  methods: {
    checkHankaku(str) {
      return String(str).match(/^[0-9a-zA-Z ]*$/) ? false : 1
    },
    checkText(str) {
      return String(str).match(/^[\- A-Za-z0-9]+$/) ? false : 1
    },
    checkTextComma(str) {
      return String(str).match(/^[\-\, A-Za-z0-9]+$/) ? false : 1
    },
    checkTextKana(str) {
      return String(str).match(/^[　ァ-ー]+$/) ? false : 1
    },
    checkTextSharp(str) {
      return String(str).match(/^[\-\#\, A-Za-z0-9]+$/) ? false : 1
    },
    checkNum(str) {
      return String(str).match(/^[\- 0-9]+$/) ? false : 1
    },
    checkEmail(str) {
      return String(str).match(/^[\w\-\._\+]+@[\w\-\._]+\.[A-Za-z\.0-9]{2,}$/) ? false : 1
      // return String(str).match(/^[\w\-\._]+@[\w\-\._]+\.[A-Za-z\.]{2,}$/) ? false : 1
    },
    checkName(str) {
      return String(str).match(/^[\_\-\(\)\. A-Za-z0-9]+$/) ? false : true
    },
    checkSiteurl(str) {
      return String(str).match(/^(http(s)?:\/{2}[\w!?/+\-_~;.,*&@#$()'[\]]+\..+)?$/) ? false : true
    }
  }
}
