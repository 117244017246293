<template>
  <div id="app">
    <keep-alive>
      <router-view :key="$route.fullPath"></router-view>
    </keep-alive>
    <BtnTop />
  </div>
</template>

<script>
import BtnTop from '@/components/AppBtnTop'
import router from './router';

export default {
  name: 'App',
  components: {
    BtnTop,
    router
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/index.scss';
</style>
