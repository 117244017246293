import { data, API } from '@/shared';

export default {
  namespaced: true,
  state: {
    faq: null,
    bbs: null,
    isNotice: true
  },
  mutations: {
    updateFaq(state, faq) {
      state.faq = faq;
    },
    updateBbs(state, bbs) {
      state.bbs = bbs;
    },
    setIsNotice(state, value) {
      state.isNotice = value;
    }
  },
  actions: { ///sites?lang=&category=&genre=&sortby=&offset=&limit=
    async getFaqAction({ commit }, lang) {
      const faq = await data.getData("/data/faq_" + lang + ".json");
      commit("updateFaq", faq);
    },
    async getBbsAction({ commit }, params) {
      const bbs = await data.getData(`${API}/bbs`, params);
      commit("updateBbs", bbs);
    },
    updateIsNotice({ commit }, value) {
      console.log('isNotice: '+value)
      commit('setIsNotice', value);
    }
  }
}
