import { data } from '@/shared';

export default {
  namespaced: true,
  state: {
    policy: null,
    performer: null
  },
  mutations: {
    updatePolicy(state, policy) {
      state.policy = policy;
    },
    updatePf(state, performer) {
      state.performer = performer;
    }
  },
  actions: {
    async getPolicyAction({ commit }) {
      const policy = await data.getData("/data/adtools/policy.json");
      commit("updatePolicy", policy);
    },
    async getPfAction({ commit }) {
      const performer = await data.getDX("https://bn.dxlive.com/json/getNGPerformers");
      commit("updatePf", performer);
      return performer
    }

  }
}
