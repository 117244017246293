import Vue from 'vue';
import Vuex from 'vuex';
import localeModules from './modules/locales';
import optionsModules from './modules/options';
import sitesModules from './modules/sites';
import adsModules from './modules/ads';
import salesModules from './modules/sales';
import accountsModules from './modules/accounts';
import toolsModules from './modules/tools';
import mailsModules from './modules/mails';
import layoutModules from './modules/layout';
import legalsModules from './modules/legals';
import authsModules from './modules/auths';

const initialState = () => ({
  user: null,
  settings: {}
});
const state = initialState();
const mutations = {
  resetStore(state) {
    Object.keys(state).forEach(key => {
      state[key] = initialState()[key];
    });
  }
};

Vue.use(Vuex)
export default new Vuex.Store({
  strict: process.env.VUE_APP_ENV !== 'production',
  state,
  mutations,
  modules: {
    locales: localeModules,
    opt: optionsModules,
    sites: sitesModules,
    ads: adsModules,
    sales: salesModules,
    accounts: accountsModules,
    tools: toolsModules,
    mails: mailsModules,
    layout: layoutModules,
    legals: legalsModules,
    auths: authsModules
  }
})