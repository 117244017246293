import { data, API } from '@/shared';

export default {
  namespaced: true,
  state: {
    layout: null
  },
  mutations: {
    updateLayout(state, newLayout) {
      state.layout = newLayout;
    }
  },
  actions: {
    async getLayoutAction({ commit }) {
      // const layout = await data.getData(`${API}/layout`);
      const layout = await data.getJsonData("/data/layout.json");
      commit("updateLayout", layout);
      return layout
    },
    async updateLayoutAction({ commit }, params) {
      const layout = await data.postData(`${API}/layout`, param);
      commit("updateLayout", layout);
    }
  }
}
